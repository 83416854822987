import React from "react";

function TermsConditions() {
  return (
    <div>
      <div class="page-header wf-section">
        <h1 class="h1-title">Terms &amp; Conditions</h1>
        <p class="subheading-text">The nitty gritty legal stuff.</p>
      </div>
      <div class="section-browse wf-section">
        <div class="browse-container w-container">
          <div class="w-richtext">
            <p>
              <strong>Introduction</strong>
              <br />
              <br />
              Welcome to the OSCEhub (OSCEhub Limited) website. Use of this site
              is conditional on accepting the Terms and Conditions for use,
              please ensure that you read through them thoroughly and understand
              them prior to using the OSCEhub website.
              <br />
              <br />
              These Terms and Conditions apply to the use of the services
              provided by OSCEhub Limited. If you do not agree to be bound by
              these Terms and Conditions do not use this website.
              <br />
              <br />
              Terms and Conditions may change over time; those applicable to
              subscribers are those which are current and agreed to when a user
              subscribes.
              <br />
              <br />
              Both the website and the subscription process are in English only.
              If you have a question regarding the Terms and Conditions please do
              not hesitate to contact us at hello@oscehub.co.uk.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Subscription &amp; Cancellation Policy</strong>
              <br />
              <br />
              The price you pay for OSCEhub is indicated on the website.
              Subscribers are required to make payments in British pounds
              sterling (GBP). If you are paying from a non-UK pound sterling
              account then your card provider will calculate the exchange rate of
              your transaction.
              <br />
              <br />
              Please note that if you wish to cancel any subscription that you
              have purchased after our contract comes into effect, cancellation
              shall be at our discretion and if accepted may be subject to a
              cancellation charge. You hereby agree that due to the nature of the
              service provided by OSCEhub Limited that cancellation or refund of
              your subscription is not permitted once the service has started.
              <br />
              <br />
              You undertake to us that all the details you give to us while
              using the website are correct in particular that the credit or
              debit card you are using is your own and that there are sufficient
              funds to cover the cost of the service.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Usage of Website</strong>
              <br />
              <br />
              By using our website, whether as a registered user or
              non-registered user, you are indicating that you accept these Terms
              and Conditions and agree to abide by them.
              <br />
              <br />
              Accounts are personal to the subscriber and for their sole and
              non-commercial home use. Passwords and other log on details must
              not be passed on or shared to others. We have the right to take
              action if this requirement is not adhered to including, but not
              limited to, immediate termination of your subscription (without a
              refund).
              <br />
              <br />
              If you use or attempt to use the service for purposes other than
              personal, individual revision, including but not limited to copying
              any content from the oscehub.co.uk website, or attempt to tamper,
              hack, modify or otherwise use the website in any way that is likely
              to compromise or corrupt the security or functionality of the
              service provided by OSCEhub Limited, your account will
              be terminated and you will be subject to damages and other
              penalties, including criminal prosecution where available.
              <br />
              <br />
              Whilst we will endeavour to ensure that the website availability
              is not interrupted, access may be restricted from time to time to
              allow for site maintenance or upgrade. Website availability may
              also be interrupted due to technical problems. Again, we will
              always try to resolve these as soon as possible but we cannot
              accept responsibility for any loss or damages that may result from
              this. If website failure occurs then we will extend user
              accounts by a day for every hour the website is unavailable, if
              this is requested.
              <br />
              <br />
              The website has been optimised for desktop use. Use of other
              equipment to access the site may result in restricted
              functionality.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>External links</strong>
              <br />
              <br />
              For the convenience of subscribers the OSCEhub website contains
              links to external sites. The inclusion of a link does not imply
              endorsement or approval of the site or its contents which is
              independent of OSCEhub Limited and over which we have no control.
              As such we can accept no responsibility for the contents or any
              loss or damages that may result from using these external sites.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Copyright</strong>
              <br />
              <br />
              The OSCEhub site is copyright protected and its content, design,
              layout,databases or graphics shall not be reproduced, stored in
              any form or by any means including but not limited to electronic,
              paper, mechanical, photocopying,recording, or broadcasting, nor
              shall the site be shown in a public gathering.You may only use the
              material available for your own personal non-commercial home use
              provided that it is not changed and copyright notices remain;
              OSCEhubLimited retains the intellectual property rights on all
              material from their website. You shall not create any derivative
              work or make any other adaptation,without our prior written
              consent.
              <br />
              <br />
              If you use or attempt to use the service for purposes other than
              personal individual revision, including but not limited to copying
              any content (e.g.OSCE station scenarios, mark schemes and/or actor
              briefs), tampering, hacking,modifying or otherwise corrupting the
              security or functionality of the service provided by OSCEhub
              Limited, your account will be terminated and you will be subject to
              damages and other penalties, including criminal prosecution
              where available.
              <br />
              <br />
              All rights not explicitly granted in these Terms and Conditions or
              in any written license remain reserved.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Limitation of liability</strong>
              <br />
              <br />
              This website is provided as a resource to aid revision and makes
              no representation, express or implied, that the drug dosages on the
              website are correct. Users must therefore check with a recognised
              formulary such as theBritish National Formulary prior to
              prescribing.
              <br />
              <br />
              Whilst reasonable care is taken to ensure the accuracy of
              information on this site, to the fullest extent permitted by law
              the material and information displayed on our website is provided
              without any guarantees, conditions or warranties as to the
              correctness, accuracy, reliability, usefulness or otherwise of
              information provided.
              <br />
              <br />
              Medical knowledge is constantly evolving and to the fullest extent
              permitted bylaw, OSCEhub Limited does not accept any
              responsibility or legal liability for any errors or the misuse or
              misapplication of material on the website.
              <br />
              <br />
              OSCEhub Limited shall not be liable to users of this site by
              reason of any representation or any implied warranty, condition or
              other term, or any duty at common law, or under the express terms
              of the contract for any loss of profit or any direct or indirect,
              special or consequential loss, damage, costs,expenses or other
              claims of any kind (whether caused by the negligence of
              OSCEhubLimited, its servants or agents or otherwise) which arise
              out of or in connection with the content and other information
              available on this website except as expressly provided in these
              conditions.
              <br />
              <br />
              OSCEhub Limited shall not be liable to users or others or be
              deemed in breach of contract by reason of any delay or failure to
              perform any of its obligations in relation to the website if this
              was due to any cause beyond the reasonable control of OSCEhub
              Limited.
              <br />
              <br />
              In no event shall OSCEhub Limited total liability to any user for
              all damages,losses, and other causes of action (whether in
              contract or not) exceed the amount paid to OSCEhub Limited for use
              of this site. The maximum liability for any claim from a user shall
              not exceed the current subscription fee which have been paid.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Privacy</strong>
              <br />
              <br />
              All personal data collected by OSCEhub Limited is governed by our
              privacy policy. By using this site you consent to use of personal
              data in accordance with the privacy policy.
            </p>
            <p>
              ‍<br />
              <br />
              <strong>Law governing Terms and Conditions</strong>
              <br />
              <br />
              These Terms and Conditions shall be construed and governed
              exclusively by the laws of England and Wales. However, we retain
              the right to bring proceedings for a breach of these Terms and
              Conditions in any relevant country.
              <br />
              <br />
              These terms and conditions do not otherwise affect your statutory
              rights.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
